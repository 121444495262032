import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";

const NAVBAR_WIDTH_PX = 150;

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger() {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-brand" style={{ minHeight: "68px" }}>
          <div style={{ backgroundColor: "white" }}>
            <div
              className="navbar-item"
              style={{
                marginLeft: this.state.active ? `${NAVBAR_WIDTH_PX}px` : "0px",
                transition: "all 0.3s ease-in-out",
                position: this.state.active ? "fixed" : "unset",
                backgroundColor: "white",
              }}
            >
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                role="menuitem"
                tabIndex={0}
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>

          <div style={{ flex: 1 }} />

          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Nina Tomczyk" style={{ width: "88px" }} />
          </Link>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu is-active`}
          style={{
            width: `${NAVBAR_WIDTH_PX}px`,
            position: "fixed",
            marginLeft: this.state.active ? "0px" : `-${NAVBAR_WIDTH_PX}px`,
            transition: "all 0.3s ease-in-out",
            fontSize: "1.2rem",
          }}
        >
          <div className="navbar-start custom-navbar">
            <Link className="navbar-item" to="/projects">
              Projects
            </Link>
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
