import * as React from "react";

import logo from "../img/logo_white.svg";
import behance from "../img/social/behance.svg";
import email from "../img/social/email.svg";
import linkedin from "../img/social/linkedin.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        className="footer has-background-black"
        style={{ padding: "3rem 0" }}
      >
        <div className="container has-background-black">
          <div className="columns is-vcentered">
            <div className="column is-2  has-text-left has-text-centered-mobile">
              <div style={{ padding: "0.5rem 0.75rem" }}>
                <img
                  width="88px"
                  style={{
                    height: `28px`,
                  }}
                  src={logo}
                  alt="Nina Tomczyk"
                />
              </div>
            </div>
            <div className="column is-4 social has-text-left has-text-centered-mobile">
              <a title="email" href="mailto:ni.tomczyk@gmail.com">
                <img
                  src={email}
                  alt="Email"
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
              <a title="behance" href="https://www.behance.net/nina_tomczyk">
                <img
                  src={behance}
                  alt="Behance"
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
              <a
                title="linkedin"
                href="https://www.linkedin.com/in/nina-tomczyk-b21569255/"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  style={{ width: "1em", height: "1em" }}
                />
              </a>
            </div>
            <div className="column is-6 has-text-white has-text-centered-mobile has-text-right">
              <div
                style={{
                  padding: "0.5rem 0.75rem",
                  textTransform: "uppercase",
                  fontSize: `0.75rem`,
                }}
              >
                Design by Nina Tomczyk
                <br />
                Developed by Imre Nagy
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
